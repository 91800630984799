<template>
  <div class="w-block__post-toolbar-1">
    <v-container v-if="$route.name === 'index'" class="pa-4">
      <v-sheet color="#E5F1F9" dark class="pa-3">
        <v-flex xs12>
          <div class="custom-color">
            Chaque mois, tentez de gagner un beau cadeau ! Pour cela, il vous
            suffit d’écouter notre dernier podcast en ligne et
            <br />

            de répondre à la question du mois via le formulaire.
            <br />

            Bonne chance à tous !
            <br />
            <v-btn
              color="accent"
              class="mt-2"
              @click.stop="showFirstFeedbackModal = true"
            >
              JE TENTE MA CHANCE
            </v-btn>
            <w-feedback-popup
              id="firstFeedback"
              v-model="showFirstFeedbackModal"
            />
          </div>
        </v-flex>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import WFeedbackPopup from '../components/WFeedbackPopup'
export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFirstFeedbackModal: false,
      showSecondFeedbackModal: false,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.w-block__post-toolbar-1 {
  justify-content: center;
  text-align: center;
}
.v-btn__content {
  background-color: transparent;
}
.v-btn::before {
  background-color: transparent;
}
.v-card {
  border-radius: 8px;
}
.v-sheet {
  border-radius: 8px;
}
.custom-color {
  color: #000000;
}
.v-btn {
  border-radius: 4px;
}
</style>
