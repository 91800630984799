<template>
  <v-dialog v-model="open" width="700" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-vinci-form-modal': true,
        'w-vinci-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/mobiletalent.png')
            : require('~/assets/images/desktoptalent.png')
        "
        alt="image popup"
        :max-height="$voicer.isMobile ? 368 : undefined"
        @click="handleClick"
      />
      <div class="text-alternate pa-3">
        L’EMISSION EST EN LIGNE, ECOUTEZ ET VOTEZ POUR VOTRE TALENT PREFERE
        JUSQU’AU 17 janvier !
        <br />
        Pour chaque vote exprimé, 1€ sera reversé aux banques alimentaires qui
        luttent contre la précarité et le gaspillage alimentaire et que nous
        soutenons chez Sodexo à travers Stop Hunger. <br />
        On compte sur vous !
      </div>
      <v-btn
        ref="btn"
        color="accent"
        depressed
        class="ma-2 white--text"
        href="https://forms.office.com/e/YxJCyHftnR?origin=lprLink"
      >
        <v-icon color="#ffffff" class="mr-2"> mdi-message-processing </v-icon>
        JE VOTE
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import localstore from 'store'
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
    }
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
  },
}
</script>
<style lang="scss">
.headline {
  font-size: 15px;
}
.w-vinci-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .body-1 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .text-alternate {
    color: #184a8b;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    display: flex;
    overflow: auto;
  }
}
</style>
